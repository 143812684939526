.App {
  width: 100%;
  height: 100vh;
  padding: 1em;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  padding: 10px;
}

.col {
  /*@apply bg-amber-600 flex flex-1 flex-col justify-center text-center;*/
  /*border: solid;*/
  /*border-radius: 10px;*/
  margin-top: 10px;
}

.cell {
  /*@apply bg-blue-300 text-center;*/
  border: solid;
  border-width: 1px;
  border-radius: 50px;
  border-color: white;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #6ba8f6;
  box-shadow: 5px 5px 5px 2px rgb(0, 0, 0);
  font-family: Helvetica, serif;
  font-weight: bolder;
  font-size: 12px;
  /*grid-column: 1 / 3;*/
  /*grid-row: 1;*/
}

.icon {
  width: 20px;
}

.active {
  background-color: #6ba8f6;
  color: black;
}

.active .icon {
  fill: white;
}

.header {
  margin: 10px;
  font-size: x-large;
  font-family: Helvetica, serif;
  font-weight: bolder;
  color: #FFFFFF;
}

.topHeader {
  font-size: xx-large;
  color: #6ba8f6;
}


